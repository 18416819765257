<template>
  <modal
      @closed="handleClose"
      :clickToClose="false"
      adaptive
      :max-width="900"
      :height="400"
      :max-height="400"
      name="add-new-point-modal"
      class="add-new-point-modal"
  >
    <div class="flex flex-col justify-between h-full">
      <div class="flex flex-col items-center justify-between p-6">
        <div class="text-center">
          <form @submit.prevent="onFormSubmit">
          <ul class="text-xl">
            <li><b>Zone:</b>
              <select v-model="pointValues.status" class="text-black p-2 mr-3 ml-3 rounded-lg" required>
                <option
                    v-for="status in statuses"
                    :key = "status.id"
                    :value="status.value"
                    :selected="pointValues.status === status.value"
                >
                  {{ status.value }}
                </option>
              </select>
            </li>
            <li>
              <p class="mb-2 font-bold">Desc: </p>
              <textarea v-model="pointValues.address" class="border rounded desc p-2" rows="3" />
            </li>
          </ul>
            <button type="submit" class="mt-2 p-2 px-6 rounded-xl text-white text-xl font-bold add-btn">{{pointForEdit.id ? 'Save' : 'Add'}}</button>
          </form>
        </div>
      </div>
      <div class="text-right p-5">
        <button class="p-2 px-6 rounded-xl text-xl font-bold close-btn" @click="handleClose">Zamknij</button>
      </div>
    </div>
  </modal>
</template>

<script>
import {statuses} from "@/utils/constants";

export default {
  name: "AddEditPointModal",
  data: () => ({
    statuses: statuses,
    pointValues: {
      status: '',
      address: '',
    }
  }),
  props: {
    showModal: {
      type: Boolean,
    },
    pointForEdit: {
      type: Object,
    }
  },
  watch: {
    showModal(value) {
      value ? this.show() : this.hide();
    },
    pointForEdit(point) {
      if (point.id) {
        this.pointValues = {...this.pointValues, ...point};
      }
    },
  },
  methods: {
    show () {
      this.$modal.show('add-new-point-modal');
    },
    hide () {
      this.$modal.hide('add-new-point-modal');
    },
    handleClose() {
      this.$emit('close-modal');
      this.pointValues.status = '';
      this.pointValues.address = '';
    },
    onFormSubmit() {
      if (this.pointForEdit.id) {
        this.$emit('edit-point', this.pointValues)
      } else {
        this.$emit('add-point', this.pointValues)
      }
    },
  },
}
</script>

<style scoped>
li {
  font-size: 1.15rem;
  margin-top: 15px;
}

.add-btn {
  background-color: forestgreen;
}

.desc {
  border-color: black;
  width: 350px;
}

@media screen and (max-width: 600px) {
  .desc {
    width: 300px;
  }
}
</style>
