<template>
  <modal @closed="handleClose" adaptive :max-width="650" :height="'auto'" class="settings-modal" name="settings">
    <div class="flex flex-col justify-between h-full custom-modal">
      <div class="flex flex-col items-center justify-between p-6">
        <div class="info-bloc text-center">
          <h2 class="text-3xl font-bold mb-3">Ustawienia</h2>
          <ul v-show="showModal">
            <li>
              <span class="em-fieldset">
                <span class="mr-2 text-black">Edit mode: </span>
                <input class="mr-2 rounded p-1" v-model="password" type="password" placeholder="password" />
                <button @click="setMode" class="text-black ml-auto font-bold">{{ editMode ? 'Deactivate' : 'Activate' }}</button>
              </span>
              <p class="error-message" v-if="passwordError">{{ passwordError }}</p>
            </li>
<!--            <li>-->
<!--              Group points-->
<!--              <toggle-button-->
<!--                  :value="groupMarkers"-->
<!--                  @change="handleChangeGroupMarkers"-->
<!--                  :color="toggleSettings"-->
<!--                  class="ml-1"-->
<!--              />-->
<!--            </li>-->
            <li>
              Filter zones:
              <select @change="handleSelectFilter($event)" class="text-black p-2 mr-3 ml-3 rounded-lg">
                <option
                    v-for="filter in filterOptions"
                    :key="filter.id"
                    :value="filter.value"
                    :selected="selectedFilter === filter.value"
                    class="p-2 font-bold"
                >
                  {{ filter.value }}
                </option>
              </select>
            </li>
<!--            <li>-->
<!--              Filter private:-->
<!--              <select @change="handleFilterPrivate($event)" class="text-black p-2 mr-3 ml-3 rounded-lg">-->
<!--                <option-->
<!--                    v-for="filter in filterPrivate"-->
<!--                    :key="filter.id"-->
<!--                    :value="filter.value"-->
<!--                    :selected="privateFilter === filter.value"-->
<!--                >-->
<!--                  {{ filter.value }}-->
<!--                </option>-->
<!--              </select>-->
<!--            </li>-->
<!--            <li>-->
<!--              <div class="flex justify-center items-center">-->
<!--                <img :src="getIcons('INP')" class="icon-img"/>-->
<!--                Show INP points-->
<!--                <toggle-button-->
<!--                    :value="showINPPoints"-->
<!--                    @change="handleChangeShowINP"-->
<!--                    :color="toggleSettings"-->
<!--                    class="ml-1 mr-4"-->
<!--                />-->
<!--              </div>-->
<!--            </li>-->
<!--            <li>-->
<!--              <div class="flex justify-center items-center">-->
<!--                <img :src="getIcons('R')" class="mr-2 icon-img"/>-->
<!--                Show R points-->
<!--                <toggle-button-->
<!--                        :value="showRPoints"-->
<!--                        @change="handleChangeShowR"-->
<!--                        :color="toggleSettings"-->
<!--                        class="ml-1 mr-4"-->
<!--                />-->
<!--              </div>-->
<!--            </li>-->
<!--            <li>-->
<!--              <div class="flex justify-center items-center">-->
<!--                <img :src="getIcons('K')" class="mr-1 icon-img"/>-->
<!--                Show K points-->
<!--                <toggle-button-->
<!--                        :value="showKPoints"-->
<!--                        @change="handleChangeShowK"-->
<!--                        :color="toggleSettings"-->
<!--                        class="ml-1 mr-4"-->
<!--                />-->
<!--              </div>-->
<!--            </li>-->
<!--            <li>-->
<!--              <div class="flex justify-center items-center">-->
<!--                <img :src="getIcons('Halter')" class="mr-2 icon-img"/>-->
<!--                Show Halter points-->
<!--                <toggle-button-->
<!--                    :value="showHalterPoints"-->
<!--                    @change="handleChangeShowHalter"-->
<!--                    :color="toggleSettings"-->
<!--                    class="ml-1 mr-4"-->
<!--                />-->
<!--              </div>-->
<!--            </li>-->
<!--            <li>-->
<!--              <div class="flex justify-center items-center">-->
<!--                <img :src="getIcons('TOP')" class="mr-2 icon-img"/>-->
<!--                Show TOP points-->
<!--                <toggle-button-->
<!--                    :value="showTopPoints"-->
<!--                    @change="handleChangeShowTop"-->
<!--                    :color="toggleSettings"-->
<!--                    class="ml-1 mr-4"-->
<!--                />-->
<!--              </div>-->
<!--            </li>-->
<!--            <li>-->
<!--              <div class="flex justify-center items-center">-->
<!--                <img :src="getIcons('Maksymalne')" class="mr-2 icon-img"/>-->
<!--                Show Maksymalne points-->
<!--                <toggle-button-->
<!--                    :value="showMaxPoints"-->
<!--                    @change="handleChangeShowMax"-->
<!--                    :color="toggleSettings"-->
<!--                    class="ml-1 mr-4"-->
<!--                />-->
<!--              </div>-->
<!--            </li>-->
<!--            <li>-->
<!--              <div class="flex justify-center items-center">-->
<!--                <img :src="getIcons('HotSpots')" class="mr-2 icon-img"/>-->
<!--                Show HotSpots points-->
<!--                <toggle-button-->
<!--                    :value="showHotSpotsPoints"-->
<!--                    @change="handleChangeShowHotSpots"-->
<!--                    :color="toggleSettings"-->
<!--                    class="ml-1 mr-4"-->
<!--                />-->
<!--              </div>-->
<!--            </li>-->
<!--            <li>-->
<!--              <div class="flex justify-center items-center">-->
<!--                <img :src="getIcons('InpTop')" class="mr-2 icon-img"/>-->
<!--                Show InpTop points-->
<!--                <toggle-button-->
<!--                    :value="showInpTopPoints"-->
<!--                    @change="handleChangeShowInpTop"-->
<!--                    :color="toggleSettings"-->
<!--                    class="ml-1 mr-4"-->
<!--                />-->
<!--              </div>-->
<!--            </li>-->
          </ul>
        </div>
      </div>
      <div class="text-right p-5 pb-0">
        <button class="p-2 px-6 bg-orange-500 rounded-xl text-xl font-bold close-btn" @click="handleClose">Zamknij
        </button>
      </div>
      <div class="text-center">
        <small class="text-gray-500">09-04-2021</small>
      </div>
    </div>
  </modal>
</template>

<script>
import {statuses} from "@/utils/constants";

export default {
  name: "AppSettingsModal",
  data: () => ({
    toggleSettings: {checked: '#6ad46a', unchecked: '#000000'},
    filterOptions:   [{id: 0, value: '--ALL--', color: 'white'}, ...statuses],
    password: null,
    passwordError: null,
    filterPrivate: [
      {id: 7, value: 'All', color: 'black'},
      {id: 1, value: 'Private', color: 'orange'},
      {id: 2, value: 'non-Private', color: 'brown'},
    ]
  }),
  props: {
    showModal: {
      type: Boolean,
    },
    types: {
      type: Array,
    },
    showINPPoints: {
      type: Boolean,
      default: false,
    },
    showRPoints: {
      type: Boolean,
      default: false,
    },
    showKPoints: {
      type: Boolean,
      default: false,
    },
    showHalterPoints: {
      type: Boolean,
      default: false,
    },
    showTopPoints: {
      type: Boolean,
      default: false,
    },
    showMaxPoints: {
      type: Boolean,
      default: false,
    },
    showHotSpotsPoints: {
      type: Boolean,
      default: false,
    },
    showInpTopPoints: {
      type: Boolean,
      default: false,
    },
    groupMarkers: {
      type: Boolean,
      default: false,
    },
    selectedFilter: {
      type: String
    },
    editMode: {
      type: Boolean,
    },
    privateFilter: {
      type: String,
    }
  },
  watch: {
    showModal(value) {
      value ? this.show() : this.hide();
    },
  },
  methods: {
    getIcons(type) {
      if (this.types.length) {
        const icon = this.types.find(element => element.title.toLowerCase() === type.toLowerCase())
        return icon ? icon.icon : require(`@/images/${type.toLowerCase()}.png`)
      }
      else return 'http://maps.google.com/mapfiles/kml/paddle/red-circle.png'
    },
    show() {
      this.$modal.show('settings');
    },
    hide() {
      this.$modal.hide('settings');
    },
    handleClose() {
      this.$emit('close-modal');
    },
    handleChangeShowINP({value}) {
      this.$emit('change-show-inp', value);
    },
    handleChangeShowR({value}) {
      this.$emit('change-show-r', value);
    },
    handleChangeShowK({value}) {
      this.$emit('change-show-k', value);
    },
    handleChangeShowHalter({value}) {
      this.$emit('change-show-halter', value);
    },
    handleChangeShowTop({value}) {
      this.$emit('change-show-top', value);
    },
    handleChangeShowMax({value}) {
      this.$emit('change-show-max', value);
    },
    handleChangeShowHotSpots({value}) {
      this.$emit('change-show-hotSpots', value);
    },
    handleChangeShowInpTop({value}) {
      this.$emit('change-show-inpTop', value);
    },
    handleChangeGroupMarkers({value}) {
      this.$emit('group-markers', value);
    },
    handleSelectFilter(filter) {
      this.$emit('select-filter', filter.target.value);
    },
    handleFilterPrivate(filter) {
      this.$emit('handle-private', filter.target.value);
    },
    setMode() {
      if (!this.editMode) {
        if (this.password === 'Paleta2021') {
          this.$emit('activate-edit-mode', true);
          this.passwordError = null;
        } else {
          this.passwordError = 'Invalid password';
        }
      } else {
        this.$emit('activate-edit-mode', false);
        this.password = null;
      }
    },
  },
}
</script>

<style scoped>
li {
  margin-top: 10px;
}

.icon-img {
  height: 35px;
  width: auto;
}

.em-fieldset {
  display: inline-block;
  width: 405px;
  background-color: white;
  border-radius: 7px;
  padding: 10px 10px;
  border: 1px solid red;
}

.error-message {
  color: red;
}

@media screen and (max-width: 600px) {
  .em-fieldset {
    padding: 10px 5px;
    width: 355px;
  }

  .em-fieldset input {
    width: 150px!important;
  }
}

.em-fieldset input {
  width: 200px;
}

.em-fieldset input {
  border: 1px solid black;
}
</style>